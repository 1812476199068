import React from 'react'

import InnerPage from '../components/InnerPage'

function NotFoundPage() {
    return (
        <InnerPage title="Seite nicht gefunden">Not Found</InnerPage>
    )
}

export default NotFoundPage